import createDataContext from "./createContext";
import { BaseService, UsersService } from "../api";
import moment from "moment";
import { openModal } from "../hooks/useModals";
import { Button, Grid, Stack } from "@mui/material";
import { AddHoliday } from "../components";
import { sendNotification } from "../hooks/useNotification";

const SET_HOLIDAYS = "set_holidays";

const INITIAL_STATE = {
    holidays: [],
};

const holidaysReducer = (state, action) => {
    switch (action.type) {
        case SET_HOLIDAYS: {
            const { holidays } = action.payload;
            return { ...state, holidays };
        }
        default: {
            return state;
        }
    }
};

const setHolidays = dispatch => async (userid) => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    const response = await UsersService.get(`${userid}/holidays/${today}/ALL`);
    dispatch({ type: SET_HOLIDAYS, payload: { holidays: response.data.holidays } });
};

const addHoliday = dispatch => async (userid, dictionary) => {
    const action = async (form, closeModal) => {
        if (form.isValid) {
            await UsersService.post(`/${userid}/holidays`, {
                datefrom: form.datefrom,
                dateto: form.dateto,
                userid,
                request_type: "HOLIDAY",
                status: "NEW",
                days: form.days,
                notes: form.note
            })
            sendNotification(
                dictionary["holidays"]["addSuccess"]["title"],
                dictionary["holidays"]["addSuccess"]["message"],
                "success",
                5
            );
            setHolidays(dispatch)(userid);
            closeModal();
        } else {
            sendNotification(
                dictionary["holidays"]["form"]["errors"]["invalid"]["title"],
                dictionary["holidays"]["form"]["errors"]["invalid"]["message"],
                "error",
                5
            );
        }

    };
    const form = { note: '', datefrom: '', dataeto: '', days: 1 };
    openModal(
        dictionary["holidays"]["request"],
        <AddHoliday formRef={form} />,
        "info",
        closeModal => (
            <Stack direction="row" spacing={2}>
                <Button
                    color="success"
                    variant="contained"
                    size="small"
                    onClick={async () => {
                        await action(form, closeModal);
                    }}>
                    {dictionary["confirm"]}
                </Button>
                <Button
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={closeModal}
                >
                    {dictionary["cancel"]}
                </Button>
            </Stack>
        ));
};
const deleteHoliday = dispatch => async (request, dictionary, setRequests, INITIAL_STATE) => {
    const action = async (closeModal) => {
        await BaseService.put(`/requests/${request.id}/NOT_APPROVED`)
        setRequests(INITIAL_STATE)
        closeModal()
    };
    openModal(
        dictionary["holidays"]["delete"],
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
                {dictionary["holidays"]["form"]["from"]}{": "}{moment(request.datefrom).format("DD/MM/YYYY")}
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                {dictionary["holidays"]["form"]["to"]}{": "}{moment(request.dateto).format("DD/MM/YYYY")}
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                {dictionary["holidays"]["days"]}{": "}{request.days}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                {dictionary["note"]}{": "}{request.notes}
            </Grid>
        </Grid>,
        "warning",
        closeModal => (
            <Stack direction="row" spacing={2}>
                <Button
                    color="success"
                    variant="contained"
                    size="small"
                    onClick={async () => {
                        await action(closeModal);
                    }}>
                    {dictionary["confirm"]}
                </Button>
                <Button
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={closeModal}
                >
                    {dictionary["cancel"]}
                </Button>
            </Stack>
        )
    )
};
export const { Provider, Context } = createDataContext(
    holidaysReducer,
    {
        setHolidays,
        addHoliday,
        deleteHoliday
    }, // actions
    INITIAL_STATE // initial state
);
