import createDataContext from "./createContext";
import BaseRequest from "../api/Base";
import moment from "moment";
import { ClockingsService } from "../api";
import { CP } from "../utilities";
import { sendNotification } from "../hooks/useNotification";
import { TIME_FORMAT } from '../utilities'

const SET_CALENDAR_USER_EVENTS = "set_calendar_user_events";
const SET_CALENDAR_USER_CLOCKINGS = "set_calendar_user_clockings";
const SET_CALENDAR_USER_ANOMALIES = "set_calendar_user_anomalies";
const SET_CALENDAR_USER_PERMITS = "set_calendar_user_permits";
const SET_CALENDAR_USER_HOLIDAYS = "set_calendar_user_holidays";
const SET_CALENDAR_USER_BUSINESS_TRIP = "set_calendar_user_business_trip";
// const SET_FILTERED_LIST = "set_filtered_list";

const INITIAL_STATE = {
    events: [],
    clockings: [],
    anomalies: [],
    permits: [],
    holidays: [],
    businesstrips:[],
    loading: true,
};

const CalendarReducer = (state, action) => {
    switch (action.type) {
        // case SET_FILTERED_LIST: {
        //     const { events, clockings, anomalies, permits, holidays } = action.payload;
        //     return { 
        //         ...state,
        //         events,
        //         clockings,
        //         anomalies,
        //         permits,
        //         holidays
        //     }
        // }
        case SET_CALENDAR_USER_EVENTS: {
            return {
                ...state,
                events: [...action.payload],
            };
        }
        case SET_CALENDAR_USER_CLOCKINGS: {
            return {
                ...state,
                clockings: [...action.payload],
            };
        }
        case SET_CALENDAR_USER_ANOMALIES: {
            return {
                ...state,
                anomalies: [...action.payload],
            };
        }
        case SET_CALENDAR_USER_PERMITS: {
            return {
                ...state,
                permits: [...action.payload],
            };
        }
        case SET_CALENDAR_USER_HOLIDAYS: {
            return {
                ...state,
                holidays: [...action.payload],
            };
        }
        case SET_CALENDAR_USER_BUSINESS_TRIP: {
            return {
                ...state,
                businesstrips: [...action.payload],
            };
        }
        default: {
            return state;
        }
    }
};

const setCalendarUserEvents =
    (dispatch) => async (userid, datefrom, dateto) => {
        const response = await BaseRequest.get(
            `events/ordinary/${userid}/ALL/${moment(datefrom).format('YYYY-MM-DD')}/${moment(dateto).format('YYYY-MM-DD')}`
        );
        dispatch({ type: SET_CALENDAR_USER_EVENTS, payload: response.data.events });
    };

const setCalendarUserClockings =
    (dispatch) => async (datefrom, dateto, id = null) => {
        let userid = id ? `?userid=${id}` : "";
        const response = await BaseRequest.get(
            `clockings/${moment(datefrom).format('YYYY-MM-DD')}/${moment(dateto).format('YYYY-MM-DD')}${userid}`
        );
        dispatch({ type: SET_CALENDAR_USER_CLOCKINGS, payload: response.data.clockings });
    };

const setCalendarUserAnomalies =
    (dispatch) => async (userid, datefrom, dateto) => {
        const response = await BaseRequest.get(
            `anomalies/${userid}/ALL/${moment(datefrom).format('YYYY-MM-DD')}/${moment(dateto).format('YYYY-MM-DD')}/ALL`
        );
        dispatch({ type: SET_CALENDAR_USER_ANOMALIES, payload: response.data.anomalies });
    };

const setCalendarUserPermits =
    (dispatch) => async (userid, datefrom, dateto) => {
        const response = await BaseRequest.get(
            `users/${userid}/permits/${moment(datefrom).format('YYYY-MM-DD')}/${moment(dateto).format('YYYY-MM-DD')}`
        );
        dispatch({ type: SET_CALENDAR_USER_PERMITS, payload: response.data.permits });
    };

const setCalendarUserHolidays =
    (dispatch) => async (userid, datefrom, dateto) => {
        const response = await BaseRequest.get(
            `users/${userid}/holidays/${moment(datefrom).format('YYYY-MM-DD')}/${moment(dateto).format('YYYY-MM-DD')}`
        );
        dispatch({ type: SET_CALENDAR_USER_HOLIDAYS, payload: response.data.holidays });
    };

const setCalendarUserBusinesstrips =
    (dispatch) => async (userid, datefrom, dateto) => {
        const response = await BaseRequest.get(
            `users/${userid}/businesstrip/${moment(datefrom).format('YYYY-MM-DD')}/${moment(dateto).format('YYYY-MM-DD')}`
        );
        dispatch({ type: SET_CALENDAR_USER_BUSINESS_TRIP, payload: response.data.businesstrips });
    };

const updateClock = dispatch => async (clockingID, date, time, finish, dictionary) => {
    await ClockingsService.put(`/${clockingID}/${CP}`, {
        cp: CP,
        date,
        time: `${moment(time).format('HH:mm')}:00`,
        clientdatetimeutc: moment(`${date}T${moment(time).format('HH:mm')}:00`).toISOString(true),
        id_clocking: clockingID
    });
    sendNotification(
        dictionary["clockings"]["updateSuccess"]["title"],
        dictionary["clockings"]["updateSuccess"]["message"],
        "success",
        5
    );
    finish();
}

const workingPeriodClock = dispatch => async (isworkperiod, userid, currentUser, firstDay, lastDay, dateE, dateU, timeE, timeU, dictionary, finish) => {
    let period = isworkperiod ? 'workingperiod' : 'notworkingperiod';
    let datetimein = `${moment(dateE).format('YYYY-MM-DD')} ${timeE.format(TIME_FORMAT)}:00`;
    let datetimeout = `${moment(dateU).format('YYYY-MM-DD')} ${timeU.format(TIME_FORMAT)}:00`;

    await BaseRequest.post(
        `users/${userid}/${period}/${CP}/${datetimein}/${datetimeout}`,
        {
            cp: CP,
            datetimein: datetimein,
            datetimeout: datetimeout,
            clientdatetimein: moment(datetimein).toISOString(true),
            clientdatetimeout: moment(datetimeout).toISOString(true),
            userid: userid,
        }
    );
    sendNotification(
        dictionary["clockings"]["workPeriodSuccess"]["title"],
        dictionary["clockings"]["workPeriodSuccess"]["message"],
        "success",
        5
    );
    setCalendarUserEvents(dispatch)(userid, firstDay, lastDay);
    setCalendarUserClockings(dispatch)(firstDay, lastDay, currentUser ? null : userid);
    setCalendarUserAnomalies(dispatch)(userid, firstDay, lastDay);
    setCalendarUserPermits(dispatch)(userid, firstDay, lastDay);
    setCalendarUserHolidays(dispatch)(userid, firstDay, lastDay);
    setCalendarUserBusinesstrips(dispatch)(userid, firstDay, lastDay);
    finish();
}

// const setDayUserEvents = (dispatch) => (selectedDay, { events, clockings, anomalies, permits, holidays }) => {
//     dispatch({ type: SET_FILTERED_LIST, payload: { events, clockings, anomalies, permits, holidays } });
// };

export const { Provider, Context } = createDataContext(
    CalendarReducer,
    {
        setCalendarUserEvents,
        setCalendarUserAnomalies,
        setCalendarUserPermits,
        setCalendarUserHolidays,
        setCalendarUserClockings,
        updateClock,
        workingPeriodClock,
        setCalendarUserBusinesstrips
        // setDayUserEvents
    }, // actions
    INITIAL_STATE // initial state
);
