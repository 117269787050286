import { Grid, TextField, Typography } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import React, { useEffect } from 'react';
import { useDictionary, useForm } from '../hooks';
import moment from 'moment';
import { isDateGreater, isRequired } from '../hooks/useForm';
import { dateDiff } from '../utilities';

const AddPermit = ({ formRef }) => {

    const DATE_FORMAT = "YYYY-MM-DD";
    const TIME_FORMAT = "HH:mm";
    const now = new Date();
    const INITIAL_STATE = {
        note: '',
        date: moment(now).format(DATE_FORMAT),
        startinghour: moment(now),
        minutes: new Date().setHours(0,0,0,0)
    };
    const dictionary = useDictionary();
    const validations = [
    ];

    useEffect(() => {
        formRef.note = INITIAL_STATE.note;
        formRef.date = INITIAL_STATE.date;
        formRef.startinghour = INITIAL_STATE.startinghour;
        formRef.minutes = INITIAL_STATE.minutes;
    }, []);

    const { values, isValid, errors, touched, changeHandler } = useForm(INITIAL_STATE, validations);

    formRef.isValid = isValid;

    return (
        <Grid container spacing={2} sx={{ mt: theme => theme.spacing(1) }}>
            <Grid item xs={12} md={12} lg={12}>
                <DatePicker
                    inputFormat='DD/MM/YYYY'
                    label={dictionary["permits"]["form"]["day"]}
                    value={values.date || ''}
                    onChange={newValue /* it is moment formatted */ => {
                        changeHandler("date", newValue);
                        formRef.date = newValue?.format(DATE_FORMAT);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            size="small"
                            error={Boolean(touched.date && errors.date)}
                            helperText={touched.date && errors.date}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <TimePicker
                    minutesStep={15}
                    ampm={false}
                    label={dictionary["permits"]["form"]["from"]}
                    value={values.startinghour || ''}
                    onChange={newValue /* it is moment formatted */ => {
                        changeHandler("startinghour", newValue);
                        formRef.startinghour = newValue;
                        // formRef.startinghour = `${newValue?.format(TIME_FORMAT)}:00`;
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            size="small"
                            error={Boolean(touched.startinghour && errors.startinghour)}
                            helperText={touched.startinghour && errors.startinghour}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <TimePicker
                    minutesStep={15}
                    ampm={false}
                    inputFormat={TIME_FORMAT}
                    label={dictionary["permits"]["form"]["minutes"]}
                    value={values.minutes || ''}
                    onChange={newValue /* it is moment formatted */ => {
                        changeHandler("minutes", newValue);
                        const [hours, minutes] = newValue?.format(TIME_FORMAT).split(":");
                        formRef.minutes = (parseInt(hours) * 60) + parseInt(minutes);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            size="small"
                            error={Boolean(touched.startinghour && errors.startinghour)}
                            helperText={touched.startinghour && errors.startinghour}
                        />
                    )}
                />
                
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <TextField
                    size="small"
                    minRows={2}
                    maxRows={7}
                    fullWidth
                    multiline
                    label={dictionary["note"]}
                    value={values.note}
                    onChange={({ target }) => {
                        changeHandler("note", target.value);
                        formRef.note = target.value;
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default AddPermit;