import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

function generateUUID() {
    // Public Domain/MIT
    let d = new Date().getTime(); // Timestamp
    let d2 = (performance && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16; // random number between 0 and 16
        if (d > 0) {
            // Use timestamp until depleted
            // eslint-disable-next-line no-bitwise
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            // Use microseconds since page-load if supported
            // eslint-disable-next-line no-bitwise
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        // eslint-disable-next-line no-bitwise
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
}

export const sendNotification = (title, message, type, duration) => {
    dispatchEvent(new CustomEvent('notification', { detail: { title, message, type, duration } }));
};

const useNotifications = () => {
    const [notify, setNotify] = useState([]);

    // eslint-disable-next-line no-underscore-dangle
    const _closeNotification = (_notify, id) => {
        const idx = _notify.findIndex((item) => item.id === id);
        if (idx >= 0) {
            _notify.splice(idx, 1);
        }
        return _notify;
    };

    const handleNotify = (e) => {
        let { title, type, duration, message } = e.detail;

        if(typeof message === "object" && !React.isValidElement(message)) {
            message = JSON.stringify(message);
        }
        if(typeof title === "object" && !React.isValidElement(title)) {
            title = JSON.stringify(title);
        }
        // eslint-disable-next-line no-underscore-dangle
        const _notify = [...notify];

        if (_notify.length >= 10) {
            _notify.splice(0, 1);
        }
        const id = generateUUID();
        setNotify([
            ..._notify,
            {
                title,
                message,
                type,
                id,
            },
        ]);
        setTimeout(
            () => setNotify((_notifies) => _closeNotification([..._notifies], id)),
            duration * 1000
        );
    };

    const closeNotification = (id) => {
        setNotify((_notify) => _closeNotification([..._notify], id));
    };

    useEffect(() => {
        window.addEventListener('notification', handleNotify);
        return () => window.removeEventListener('notification', handleNotify);
    }, [notify]);

    return [notify, closeNotification];
};

export default useNotifications;
