import React from "react";
import {Stack, Grid, Typography, Box, Chip, Button, Paper} from "@mui/material";
import moment from "moment";
import {
  isSameDate,
  translateMinutesInTime,
  createFirstDayOfMonth,
  createFirstDayOfNextMonth,
  deleteMillisFromTime,
} from "../utilities";
import {useDictionary, useMediaQuery} from "../hooks";
import {
  AnomalyDetailModal,
  EventDetailModal,
  PermitDetailModal,
  ClockingsDetailModal,
  HolidayDetailModal,
  BusinesstripDetailModal,
} from "../components";
import {openModal} from "../hooks/useModals";

const DayBox = ({
  commissions,
  day,
  events,
  clockings,
  anomalies,
  permits,
  holidays,
  businesstrips,
  eventTypes,
  currentDate,
  userid,
  showCalendarObj,
}) => {
  // const DayBox = ({ day, events, clockings, permits, holidays, eventTypes, currentDate, userid, showCalendarObj }) => {
  const dictionary = useDictionary();

  const firstDay = createFirstDayOfMonth(currentDate);
  const lastDay = createFirstDayOfNextMonth(currentDate);
  const [isMobile] = useMediaQuery("down", "md");

  const clockingDayArr = clockings.length > 0 ? clockings.filter((item) => isSameDate(day, item.declareddate)) : null;
  const eventDay = events.length > 0 ? events.find((item) => isSameDate(day, item.datetimefrom)) : null;
  const anomalyDay = anomalies.length > 0 ? anomalies.filter((item) => isSameDate(day, item.datetimefrom)) : null;
  const permitDay =
    permits.length > 0 ? permits.find((item) => item.status !== "DELETED" && isSameDate(day, item.datefrom)) : null;
  const holidayDay =
    holidays.length > 0
      ? holidays.find(
          (item) =>
            item.status !== "DELETED" &&
            /* item.status !== "REFUSED" && */
            moment(day).isBetween(moment(item.datefrom), moment(item.dateto), undefined, "[]")
        )
      : null;
  const businesstripDay =
    businesstrips.length > 0
      ? businesstrips.find(
          (item) =>
            item.status !== "DELETED" &&
            /* item.status !== "REFUSED" && */
            moment(day).isBetween(moment(item.datefrom), moment(item.dateto), undefined, "[]")
        )
      : null;

  const eventDescription = eventDay ? eventTypes?.find((item) => item.id === eventDay.typeid) : null;

  // PRINT ON BADGE METHODS
  const printClocking = () => {
    let clockingIn = [...clockingDayArr].filter((item) => item.clockingdirection === "IN").at(0);
    let clockingOut = [...clockingDayArr].filter((item) => item.clockingdirection === "OUT").at(-1);
    let hourE = "";
    let hourU = "";
    let directionE = "";
    let directionU = "";
    let directions = "";
    if (clockingIn) {
      hourE = deleteMillisFromTime(clockingIn.declaredtime);
      directionE = dictionary[clockingIn.clockingdirection];
    }
    if (clockingOut) {
      hourU = deleteMillisFromTime(clockingOut.declaredtime);
      directionU = dictionary[clockingOut.clockingdirection];
    }

    directions = directionE && directionU ? `${directionE}/${directionU}` : `${directionE}${directionU}`;
    return `${hourE} - ${hourU} ${dictionary["clocking"]} ${directions}`;
  };

  const printEvent = () => {
    let description = eventDescription ? dictionary["types"][eventDescription.description] : dictionary["events"];
    let hours = translateMinutesInTime(eventDay.minutescount);
    let justified = eventDay.justifications.length > 0 ? "**" : "";
    return `${description} (${hours}) ${justified}`;
  };

  const printAnomaly = (anomalyLocal) => {
    let anomalyDescription = anomalyLocal ? eventTypes?.find((item) => item.id === anomalyLocal.typeid) : null;
    let description = anomalyDescription ? dictionary["types"][anomalyDescription.description] : dictionary["events"];
    let hoursTranslated = translateMinutesInTime(anomalyLocal.minutescount);
    let hours = hoursTranslated === "00:00" ? "" : `(${hoursTranslated})`;
    let justified = anomalyLocal.justifications.length > 0 ? "*" : "";
    return `${description} ${hours} ${justified}`;
  };

  const printRequest = () => {
    let status = null;
    if (dictionary["requestStatus"][holidayDay.status]) {
      status = dictionary["requestStatus"][holidayDay.status];
    } else {
      if (holidayDay["outcomes"].length > 0) {
        status = dictionary["requestStatus"][holidayDay.outcomes[0].status];
      } else {
        status = "";
      }
    }
    return `${dictionary["requests"]["types"]["HOLIDAY"]} - ${status}`;
  };

  const printBusinesstrip = () => {
    let status = null;
    if (dictionary["requestStatus"][businesstripDay.status]) {
      status = dictionary["requestStatus"][businesstripDay.status];
    } else {
      if (businesstripDay["outcomes"].length > 0) {
        status = dictionary["requestStatus"][businesstripDay.outcomes[0].status];
      } else {
        status = "";
      }
    }
    return `${dictionary["requests"]["types"]["BUSINESSTRIP"]} - ${status}`;
  };

  const printPermit = () => {
    // let status = permitDay?.status > 0 ? dictionary["requestStatus"][permitDay.status] : "";
    let status = dictionary["requestStatus"][permitDay.status] ? dictionary["requestStatus"][permitDay.status] : "";
    return `${dictionary["requests"]["types"]["PERMIT"]} - ${status}`;
  };
  // END PRINT ON BADGE METHODS

  const checkModalBody = (type, item, closeModalRef) => {
    switch (type) {
      case "event":
        return <EventDetailModal commissions={commissions} event={item} />;
        break;
      case "anomaly":
        return <AnomalyDetailModal anomaly={item} />;
        break;
      case "holiday":
        return <HolidayDetailModal holiday={item} />;
      case "businesstrip":
        return <BusinesstripDetailModal businesstrip={item} />;
      case "permit":
        return <PermitDetailModal permit={item} />;
        break;
      case "clockings":
        return (
          <ClockingsDetailModal
            clockings={item}
            closeModalRef={closeModalRef}
            firstDay={firstDay}
            lastDay={lastDay}
            userid={userid}
          />
        );
        break;
    }
  };

  const checkModalTitle = (type, item = null) => {
    switch (type) {
      case "event":
        return printEvent();
        break;
      case "anomaly":
        return printAnomaly(item);
        break;
      case "permit":
        return printPermit();
        break;
      case "holiday":
        return printRequest();
        break;
      case "businesstrip":
        return printBusinesstrip();
        break;
      case "clockings":
        return printClocking();
        break;
    }
  };

  // OPEN MODAL METHODS
  const openDetail = (item, type) => {
    const closeModalRef = {
      current: null,
    };
    openModal(
      checkModalTitle(type, item),
      checkModalBody(type, item, closeModalRef),
      null,
      (closeModal) => {
        closeModalRef.current = closeModal;
        return (
          <Stack direction="row" spacing={2}>
            <Button color="error" variant="contained" size="small" onClick={closeModal}>
              {dictionary["close"]}
            </Button>
          </Stack>
        );
      },
      "sm"
    );
  };
  // END OPEN MODAL METHODS

  const isDayInMonth = new Date(day).getMonth() === new Date(currentDate).getMonth();

  return (
    <Box
      component={Paper}
      sx={{
        p: (theme) => theme.spacing(1),
        flex: 1,
        minHeight: "150px",
        margin: "2px",
        border: "1px solid #ddd",
        backgroundColor: isDayInMonth ? "white" : "lightgrey",
      }}
      key={`${moment(day).format("DoDD")}`}>
      <Stack>
        <Grid container>
          <Grid item xs={12} md={12} lg={12} align="right">
            <Typography variant="body2">{moment(day).format(isMobile ? "DD/MM/YYYY" : "DD")}</Typography>
            <Stack spacing={0.5} sx={{marginTop: "5px"}}>
              {clockingDayArr?.length > 0 && showCalendarObj.clockings ? (
                <Chip
                  size="small"
                  sx={{fontSize: "10px", cursor: "pointer"}}
                  label={printClocking()}
                  color={"clockings"}
                  onClick={() => openDetail(clockingDayArr, "clockings")}
                />
              ) : null}
              {eventDay && showCalendarObj.events ? (
                <Chip
                  size="small"
                  sx={{fontSize: "10px", cursor: "pointer"}}
                  label={printEvent()}
                  color={"events"}
                  onClick={() => openDetail(eventDay, "event")}
                />
              ) : null}
              {anomalyDay && showCalendarObj.anomalies
                ? anomalyDay.map((item) => (
                    <Chip
                      size="small"
                      sx={{fontSize: "10px", cursor: "pointer"}}
                      label={printAnomaly(item)}
                      color={"anomalies"}
                      onClick={() => openDetail(item, "anomaly")}
                      key={"anomaly-" + item.id}
                    />
                  ))
                : null}
              {permitDay && permitDay?.status !== "DELETED" && showCalendarObj.permits ? (
                <Chip
                  size="small"
                  sx={{fontSize: "10px"}}
                  label={printPermit()}
                  color={"requests"}
                  onClick={() => openDetail(permitDay, "permit")}
                />
              ) : null}
              {holidayDay &&
              holidayDay?.status !== "DELETED" &&
              /* holidayDay?.status !== "DELETED" &&
              holidayDay?.status !== "REFUSED" && */
              showCalendarObj.requests ? (
                <Chip
                  size="small"
                  sx={{fontSize: "10px", cursor: "pointer"}}
                  label={printRequest()}
                  color={"requests"}
                  onClick={() => openDetail(holidayDay, "holiday")}
                />
              ) : null}
              {businesstripDay &&
              businesstripDay?.status !== "DELETED" &&
              /*  businesstripDay?.status !== "DELETED" &&
              businesstripDay?.status !== "REFUSED" && */
              showCalendarObj.businesstrips ? (
                <Chip
                  size="small"
                  sx={{fontSize: "10px", cursor: "pointer"}}
                  label={printBusinesstrip()}
                  color={"businesstrips"}
                  onClick={() => openDetail(businesstripDay, "businesstrip")}
                />
              ) : null}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default DayBox;
