import createDataContext from "./createContext";
import { BaseService, UsersService } from "../api";
import moment from "moment";
import { Button, Grid, Stack } from "@mui/material";
import { AddPermit } from "../components";
import { openModal } from "../hooks/useModals";
import { sendNotification } from "../hooks/useNotification";
import { deleteMillisFromTime, translateMinutesInTime } from "../utilities";

const SET_PERMITS = "set_permits";

const INITIAL_STATE = {
    permits: [],
};

const permitsReducer = (state, action) => {
    switch (action.type) {
        case SET_PERMITS: {
            const { permits } = action.payload;
            return { ...state, permits };
        }
        default: {
            return state;
        }
    }
};

const setPermits = dispatch => async (userid) => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    const response = await UsersService.get(`${userid}/permits/${today}/ALL`);
    dispatch({ type: SET_PERMITS, payload: { permits: response.data.permits } });
};
const addPermit = dispatch => (userid, dictionary) => {
    const action = async (form, closeModal) => {
        if (form.isValid) {
            await UsersService.post(`/${userid}/permits`, {
                datefrom: form.date,
                dateto: form.date,
                minutes: form.minutes,
                request_type: "PERMIT",
                startinghour: moment(form.startinghour).format('HH:mm')+':00',
                status: "NEW",
                userid,
                notes: form.note
            });
            sendNotification(
                dictionary["permits"]["addSuccess"]["title"],
                dictionary["permits"]["addSuccess"]["message"],
                "success",
                5
            );
            setPermits(dispatch)(userid);
            closeModal();
        } else {
            sendNotification(
                dictionary["permits"]["form"]["errors"]["invalid"]["title"],
                dictionary["permits"]["form"]["errors"]["invalid"]["message"],
                "error",
                5
            );
        }

    };
    const form = { note: '', date: '', minutes: '', startinghour: '' };
    openModal(
        dictionary["permits"]["request"],
        <AddPermit formRef={form} />,
        "info",
        closeModal => (
            <Stack direction="row" spacing={2}>
                <Button
                    color="success"
                    variant="contained"
                    size="small"
                    onClick={async () => {
                        await action(form, closeModal);
                    }}>
                    {dictionary["confirm"]}
                </Button>
                <Button
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={closeModal}
                >
                    {dictionary["cancel"]}
                </Button>
            </Stack>
        ));
};
const deletePermit = dispatch => async (request, dictionary, setRequests, INITIAL_STATE) => {
    const action = async (closeModal) => {
        await BaseService.put(`/requests/${request.id}/NOT_APPROVED`)
        setRequests(INITIAL_STATE)
        closeModal()
    };
    openModal(
        dictionary["permits"]["delete"],
        <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
                {dictionary["permits"]["day"]}{": "}{moment(request.datefrom).format("DD/MM/YYYY")}
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                {dictionary["permits"]["from"]}{": "}{deleteMillisFromTime(request.startinghour)}
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                {dictionary["permits"]["to"]}{": "}{deleteMillisFromTime(request.endinghour)}
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                {dictionary["permits"]["duration"]}{": "}{translateMinutesInTime(request.minutes)}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                {dictionary["note"]}{": "}{request.notes}
            </Grid>
        </Grid>,
        "warning",
        closeModal => (
            <Stack direction="row" spacing={2}>
                <Button
                    color="success"
                    variant="contained"
                    size="small"
                    onClick={async () => {
                        await action(closeModal);
                    }}>
                    {dictionary["confirm"]}
                </Button>
                <Button
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={closeModal}
                >
                    {dictionary["cancel"]}
                </Button>
            </Stack>
        )
    )
};
export const { Provider, Context } = createDataContext(
    permitsReducer,
    {
        setPermits,
        addPermit,
        deletePermit
    }, // actions
    INITIAL_STATE // initial state
);