import {Box, Container, Grid} from "@mui/material";
import React, {useContext, useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import {AppBarMenu, Modals, Notifications, Sidebar, SidebarMenu} from "./components";
import {LanguageContext} from "./contexts";
import {useLoading, useModals, useNotification, useUser} from "./hooks";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {
  Login,
  NotFound,
  Landing,
} from "./pages";
import {STORE_USER, LANGUAGE_PREFERENCE, routes, userHasOneOfRoles} from "./utilities";

const Main = () => {
  const [notifications, closeNotification] = useNotification();
  const [modals, closeModal] = useModals();
  const {user, setUser} = useUser();
  const {
    state: {muiTranslation},
    onChangeLanguage,
  } = useContext(LanguageContext);
  const [loading, LoadingBackdrop] = useLoading();

  useEffect(() => {
    const _languageAbbr = localStorage.getItem(LANGUAGE_PREFERENCE);
    if (_languageAbbr !== "null" && _languageAbbr) {
      onChangeLanguage(_languageAbbr);
    } else {
      onChangeLanguage("en");
    }
  }, []);

  useEffect(() => {
    const _user = JSON.parse(localStorage.getItem(STORE_USER));
    // if (user) {
    setUser(_user);
    // }
  }, [notifications]);
  // every time that change notification check if there is user
  // in local storage and align state with it
  // this is basically for redirect on expired token,
  // see the error interceptor and check the way this is achieved

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      dateFormats={muiTranslation.calendar}
      adapterLocale={muiTranslation.calendarAbbr}>
      {loading.length > 0 && <LoadingBackdrop />}
      {notifications.length > 0 && (
        <Notifications notifications={notifications} closeNotification={closeNotification} />
      )}
      {modals.length > 0 && <Modals modals={modals} closeModal={closeModal} />}
      {user && <AppBarMenu />}
      {user && (
        <Sidebar>
          <SidebarMenu />
        </Sidebar>
      )}
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          justifyContent: "center",
          my: (theme) => theme.spacing(5),
        }}>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Routes>
              {user ? (
                <>
                  {routes.map((route) => (
                    <React.Fragment key={route.id}>
                      {route.children && route.children.length > 0 ? (
                        <>
                          {route.children.map(
                            (child) =>
                              userHasOneOfRoles(child.need, user) && (
                                <Route
                                  path={`${route.path}${child.path}`}
                                  key={child.id}
                                  element={child.element ? <child.element /> : <NotFound />}
                                />
                              )
                          )}
                        </>
                      ) : (
                        userHasOneOfRoles(route.need, user) && (
                          <Route
                            path={route.path}
                            key={route.id}
                            element={route.element ? <route.element /> : <NotFound />}
                          />
                        )
                      )}
                    </React.Fragment>
                  ))}
                  <Route path="*" element={<NotFound />} />
                </>
              ) : (
                <>
                  <Route path="/landing" element={<Landing />} />
                  <Route path="*" element={<Login />} />
                </>
              )}
            </Routes>
          </Grid>
        </Grid>
      </Container>
    </LocalizationProvider>
  );
};

export default Main;
