import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { Grid, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import moment from 'moment';
import { useDimensions, useForm } from '../hooks';
import { deleteMillisFromTime, getPosition, normalizeTime } from '../utilities';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import L from 'leaflet';

const iconPerson = new L.Icon({
  iconUrl: require("../assets/avatar_user2.png"),
  iconRetinaUrl: require("../assets/avatar_user2.png"),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: 'leaflet-div-icon'
});

const ClockingForm = ({ dictionary, lastClocking, direction, dateRef, timeRef, geolocationEnabled }) => {

  const [position, setPosition] = React.useState(null)

  const validations = [];

  const DATE_FORMAT = "YYYY-MM-DD";
  const TIME_FORMAT = "HH:mm";

  const {
    values: clockingForm,
    changeHandler,
    errors,
    touched,
    isValid
  } = useForm({
    date: lastClocking ? lastClocking.declareddate : new Date(),
    time: lastClocking ? moment(deleteMillisFromTime(lastClocking.declaredtime), TIME_FORMAT) : new Date()
  }, validations);

  useEffect(() => {
    const now = lastClocking ? lastClocking.declareddate : new Date();
    dateRef.current = moment(now).format(DATE_FORMAT);
    timeRef.current = lastClocking ? moment(deleteMillisFromTime(lastClocking.declaredtime), TIME_FORMAT) : moment(now);
    if (geolocationEnabled) {
      geoLocalize()
    }
  }, []);

  const geoLocalize = async () => {
    const { coords: { latitude, longitude } } = await getPosition();
    setPosition({ 'longitude': longitude, 'latitude': latitude })
  }

  return (
    <Grid container spacing={2} sx={{ pt: theme => theme.spacing(1) }}>
      <Grid item xs={12} md={12} lg={12}>
        <DatePicker
          inputFormat='DD/MM/YYYY'
          label={dictionary["clockings"]["form"]["date"]}
          value={clockingForm.date || ""}
          onChange={newValue /* it is moment formatted */ => {
            changeHandler("date", newValue?.format(DATE_FORMAT));
            dateRef.current = newValue?.format(DATE_FORMAT);
          }}
          renderInput={(params) => <TextField {...params} fullWidth size="small" />}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TimePicker
          ampm={false}
          label={dictionary["clockings"]["form"]["time"]}
          value={clockingForm.time || ""}
          onChange={newValue /* it is moment formatted */ => {
            changeHandler("time", newValue);
            timeRef.current = newValue;
          }}
          renderInput={(params) => <TextField {...params} fullWidth size="small" />}
        />
      </Grid>
      {position && (
        <Grid item xs={12} md={12} lg={12}>
          <MapContainer center={[position.latitude, position.longitude]} zoom={13} style={{ height: 300, width: '100%' }}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[position.latitude, position.longitude,]} icon={iconPerson}>
              <Popup>
                Tu sei qui
              </Popup>
            </Marker>
          </MapContainer>
        </Grid>
      )}
    </Grid>
  )
}

export default ClockingForm